import axios from 'axios'
import {defineStore} from 'pinia'
import {useAuthStore} from "@/stores/auth";


export const usePatientsStore = defineStore({
  id: 'patients',

  state: () => ({
    patients: [],
    patientsAlarmCountFiltered: [],
    patientAlarmCountFiltered: null,
    patient: null,
    originalPatient: null,
    newPatient: {
      id: null,
      patient_detail: {
        id: null,
        registry_patient: {
          id: null
        }
      }
    },
    OverlayAddPatient: false,
    singlePatient: null,
    custom_user: '',
    patient_detail_id: '',
    registry_patient_id: ''
  }),

  actions: {
    eliminaIndirizzo (payload) {
      return new Promise((resolve, reject) => {
        const config = {
          headers: {
            Authorization: 'Token ' + useAuthStore().userGetters.user.token
          }
        }
        axios.delete('/api/registries-patient/' + payload.registry[payload.index].id + '/', config)
          .then(res => {
            resolve(res)
          })
          .catch(err => {
            reject(err)
          })
      })
    },
    editIndirizzi (payload) {
      return new Promise((resolve, reject) => {
        const config = {
          headers: {
            Authorization: 'Token ' + useAuthStore().userGetters.user.token
          }
        }
        axios.patch('/api/registries-patient/' + payload.registry[payload.index].id + '/', { registry_type: payload.registry[payload.index].registry_type }, config)
          .then(() => {
            axios.patch('/api/registries-patient-detail/' + payload.registry[payload.index].registry_detail.id + '/', payload.registry[payload.index].registry_detail, config)
              .then(res => {
                resolve(res)
              })
              .catch(err => {
                axios.patch('/api/registries-patient/' + payload.clickedObj.id + '/', { registry_type: payload.clickedObj.registry_type }, config)
                reject(err)
              })

          })
          .catch(err => {
            reject(err)
          })

      })
    },
    editAnagrafica (payload) {
      return new Promise((resolve, reject) => {
        const config = {
          headers: {
            Authorization: 'Token ' + useAuthStore().userGetters.user.token
          }
        }
        const promises = []
        if (useAuthStore().userGetters.user.user_type === 'p') {
          if (payload.email !== usePatientsStore().singlePatientGetters.email) {
            promises.push(axios.patch('api/users/' + payload.id + '/', { email: payload.email }, config)
              .then(res => {
                return Promise.resolve(res)
              })
              .catch(err => {
                return Promise.reject(err)
              })
            )
          }
        } else if (useAuthStore().userGetters.user.user_type === 'd') {
          if (payload.email !== usePatientsStore().singlePatientGetters.email) {
            promises.push(axios.patch('api/users/' + payload.id + '/', { email: payload.email }, config)
              .then(res => {
                return Promise.resolve(res)
              })
              .catch(err => {
                return Promise.reject(err)
              })
            )
          }
        }
        promises.push(axios.patch('api/patients-detail/' + payload.patient_detail.id + '/', payload.patient_detail, config)
          .then(res => {
            return Promise.resolve(res)
          })
          .catch(err => {
            return Promise.reject(err)
          })
        )

        Promise.allSettled(promises).then(value => {
            resolve(value)
          },
          reason => {
            reject(reason)
          })
      })
    },
    getPatients (payload) {
      return new Promise((resolve, reject) => {
        const config = {
          headers: {
            Authorization: 'Token ' + useAuthStore().userGetters.user.token
          }
        }

        let url = new URL(axios.defaults.baseURL + 'api/doctors/list-patients-hr-light/')
        if (payload.queryParams !== null) {
          let params = new URLSearchParams()
          for (let i = 0; i < Object.keys(payload.queryParams).length; i++) {
            params.set(Object.keys(payload.queryParams)[i], Object.values(payload.queryParams)[i])
          }
          url.search = params
          axios.get(url.pathname + url.search, config)
            .then(res => {
              if ('alarm_history__init_timestamp__gte' in payload.queryParams){
                this.patientsAlarmCountFiltered = res.data
              }
              this.patients = res.data
              resolve(res)
            })
            .catch(err => {
              reject(err)
            })
        } else {
          axios.get(url.pathname, config)
            .then(res => {
              this.patients = res.data
              resolve(res)
            })
            .catch(err => {
              reject(err)
            })
        }
      })
    },
    setNotification (hrTimeDomainLimit) {
      return new Promise((resolve) => {
        this.notification = hrTimeDomainLimit
        resolve()
      })
    },
    getSinglePatient (id) {
      return new Promise((resolve, reject) => {
        const config = {
          headers: {
            Authorization: 'Token ' + useAuthStore().userGetters.user.token
          }
        }
        axios.get('api/patients-hr-light/' + id + '/', config)
          .then(resp => {
            this.singlePatient = resp.data
            resolve(resp)
          })
          .catch(err => {

            reject(err)
          })
      })
    },
    addNewAddress (payload) {
      return new Promise((resolve, reject) => {
        const config = {
          headers: {
            Authorization: 'Token ' + useAuthStore().userGetters.user.token
          }
        }
        payload.r.patient_detail = payload.id_patient_detail
        axios.post('api/registries-patient/', payload.r, config)
          .then(res => {
            payload.rd.registry_patient = res.data.id
            axios.post('api/registries-patient-detail/', payload.rd, config)
              .then(res => {
                resolve(res)

              })
              .catch(err => {
                reject(err)
                axios.delete('api/registries-patient/' + payload.rd.registry_patient + '/', config)

              })
          })
          .catch(err => {

            reject(err)
          })
      })
    },
    addNewPatient (payload) {
      return new Promise((resolve, reject) => {
        let that = this
        const config = {
          headers: {
            Authorization: 'Token ' + useAuthStore().userGetters.user.token
          }
        }
        axios.post('/api/users/', {
          email: payload.values.email,
          password: payload.values.password,
          confirmation: payload.values.confirmation,
          doctor: payload.values.doctor,
        })
          .then(res => {
            const userId = res.data.id
            that.new_patient_id = userId

            axios.post('/api/hr-time-domain-limit/', { customuser: res.data.id }, config)
              .then(() => {
              })
              .catch(err => {
                axios.delete('/api/users/' + res.data.id + '/', config).then(() => {
                })
                reject(err)
              })
            payload.values.custom_user = res.data.id
            axios.post('api/patients-detail/', {
              first_name: payload.values.first_name,
              last_name: payload.values.last_name,
              date_of_birth: payload.values.date_of_birth,
              birth_province: payload.values.birth_province,
              birth_country: payload.values.birth_country,
              birth_city: payload.values.birth_city,
              gender: payload.gender,
              ssn: payload.values.ssn,
              phone_number: payload.values.phone_number,
              weight: payload.values.weight,
              height: payload.values.height,
              work: payload.work,
              lifestyle: payload.lifestyle,
              marital_status: payload.statusMarital,
              custom_user: payload.values.custom_user
            }, config)
              .then(res => {
                const patientDetailId = res.data.id
                that.patient_detail_id = patientDetailId
                payload.values.patient_detail = res.data.id
                axios.post('api/registries-patient/', {
                  patient_detail: payload.values.patient_detail,
                  registry_type: payload.typeAddress
                }, config)
                  .then(res => {

                    const registryPatientId = res.data.id
                    that.registry_patient_id = registryPatientId
                    payload.values.registry_patient = usePatientsStore().registry_patient_idGetters
                    axios.post('api/registries-patient-detail/', {
                      registry_patient: registryPatientId,
                      country: payload.values.country,
                      province: payload.values.province,
                      city: payload.values.city,
                      address: payload.values.address,
                      street_number: payload.values.street_number,
                      postal_code: payload.values.postal_code,
                      telephone_number: payload.values.telephone_number,
                    }, config)
                      .then(res => {

                        resolve(res)
                      })
                      .catch(err => {
                        axios.delete('/api/users/' + res.data.id + '/', config)
                          .then(() => {
                          })
                          .catch(() => {
                          })
                        reject(err)
                      })
                  })
                  .catch(err => {
                    axios.delete('/api/users/' + res.data.id + '/', config)
                      .then(() => {
                      })
                      .catch(() => {
                      })
                    reject(err)
                  })
              })
              .catch(err => {
                axios.delete('/api/users/' + res.data.id + '/', config)
                  .then(() => {
                  })
                  .catch(() => {
                  })
                reject(err)
              })
          })
          .catch(err => {

            reject(err)
          })
      })
    }
  },

  getters: {
    patientsGetters: state => {
      return state.patients
    },
    patientGetters: state => {
      return state.patient
    },
    originalPatientGetters: state => {
      return state.originalPatient
    },
    newPatientGetters: state => {
      return state.newPatient.id
    },
    singlePatientGetters: state => {
      return state.singlePatient
    },
    custom_userGetters: state => {
      return state.custom_user
    },
    patient_detail_idGetters: state => {
      return state.newPatient.patient_detail.id
    },
    registry_patient_idGetters: state => {
      return state.newPatient.patient_detail.registry_patient.id
    },
    OverlayAddPatientGetters: state => {
      return state.OverlayAddPatient
    },
    patientsAlarmCountFilteredGetters: state => {
      return state.patientsAlarmCountFiltered
    },
    patientAlarmCountFilteredGetters: state => {
      return state.patientAlarmCountFiltered
    }
  }
})


