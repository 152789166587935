<template>
  <div id="app">
    <router-view></router-view>
    <div
      v-if="storeAlarms.allActiveAlarmsGetters && storePatient.patientsGetters.length > 0 && storeAlarms.allToatsAlarmGetters.length > 0 && storeAuth.isAuthenticated"
      class="container-notifications">
      <div @click="goToDetail($event,notification.customuser,index)" :class="{'margin-top': index === 0}"
           class="container-single-notification"
           v-for="(notification,index) in storeAlarms.allToatsAlarmGetters"
           :key="notification.id"
           :style="{opacity :(1 - (1 - ((100/storeAlarms.allToatsAlarmGetters.length) * (index + 1)/100)))}">
        <div style="margin: 0px auto 0px 25px">
          <span class="text-type">{{ returnType(notification.alarm_type) }}</span>
          <span class="text-italic"
                v-if="patients">{{
              findPatient(notification).patient_detail.first_name
            }} {{ findPatient(notification).patient_detail.last_name }}</span>
          <span class="text-italic">Inizio: {{ convertDateTime(notification.init_timestamp) }}</span>
        </div>
        <img @click="removeToast($event,index)" class="img-rotate" src="./assets/shared/plus.svg">
      </div>
      <button class="btn btn-close-all" @click="resetToast">Chiudi tutto</button>
    </div>
  </div>

</template>

<script>
import {useAlarmsStore} from "@/stores/alarms";
import {useAuthStore} from "@/stores/auth";
import {usePatientsStore} from "@/stores/patients";
import moment from "moment/moment";

export default {
  setup () {
    const storeAlarms = useAlarmsStore()
    const storePatient = usePatientsStore()
    const storeAuth = useAuthStore()
    return {
      storeAlarms,
      storePatient,
      storeAuth
    }
  },
  data () {
    return {
      allActiveAlarmsCopy: 0,
    }
  },
  created () {
    let queryParams = {
      ordering: '-init_timestamp',
      pagination: false
    }

    if (useAuthStore().isAuthenticated && useAuthStore().userGetters.user.user_type === 'd') {
      useAlarmsStore().retrieveAlarmsWithoutInterval({ queryParams: null })
        .then(() => {
          useAlarmsStore().retrieveAlarms()
            .then(() => {
            })
            .catch(() => {
            })
        })
        .catch(() => {
        })

      useAlarmsStore().getAllActiveAlarms({ queryParams: queryParams })
        .then(res => {
          useAlarmsStore().allToatsAlarm = []
          useAlarmsStore().countAllActiveAlarms = res.data.length
          this.allActiveAlarmsCopy = res.data.length
          this.checkIfPushNewToast()
          this.scrollDown()
        })
        .catch(() => {
        })
    }
    if ((window.innerWidth < 767.98) || (screen.orientation.angle > 0)) {
      this.changeVariables()
    } else {
      this.resetVariables()
    }
  },
  beforeMount () {
    //
  },
  mounted () {
    if (useAuthStore().isAuthenticated && useAuthStore().userGetters.user.user_type === 'd') {
      useAlarmsStore().retrieveAlarmsWithoutInterval({ queryParams: null })
        .then(() => {
          useAlarmsStore().retrieveAlarms()
            .then(() => {
            })
            .catch(() => {
            })
        })
        .catch(() => {
        })
    }
    window.addEventListener('resize', (e) => {
      if ((e.target.innerWidth < 767.98) || (screen.orientation.angle > 0)) {
        this.changeVariables()
      } else {
        this.resetVariables()
      }
    })
  },
  watch: {
    patients: {
      handler: function () {
        let queryParams = {
          ordering: '-init_timestamp',
          pagination: false
        }
        useAlarmsStore().getAllActiveAlarms({ queryParams: queryParams })
          .then(res => {
            useAlarmsStore().countAllActiveAlarms = res.data.length
            this.checkIfPushNewToast()
            this.allActiveAlarmsCopy = res.data.length
            this.scrollDown()
          })
          .catch(() => {

          })
      }
    }
  },
  computed: {
    patients () {
      return usePatientsStore().patientsGetters
    }
  },
  methods: {
    scrollDown () {
      let div = document.getElementsByClassName('container-notifications')[0];
      if (div) {
        div.style.scrollbarWidth = '0px';
        div.scrollTo(0, div.scrollHeight);
      }
    },
    goToDetail ($event, id, i) {
      this.$router.push({ name: 'detailPatient', params: { id: id } }).catch(() => {
      })
      this.removeToast($event, i)
    },
    removeToast (event, i) {
      event.stopPropagation()
      useAlarmsStore().allToatsAlarmGetters.splice(i, 1)
    },
    convertDateTime (data) {
      return moment(data).local().format('DD-MM-YYYY HH:mm:ss')
    },
    resetToast () {
      useAlarmsStore().allToatsAlarm = []
      this.allActiveAlarmsCopy = useAlarmsStore().allActiveAlarmsGetters
    },
    checkIfPushNewToast () {
      if (useAlarmsStore().countAllActiveAlarmsGetters > this.allActiveAlarmsCopy) {
        let delta = useAlarmsStore().countAllActiveAlarmsGetters - this.allActiveAlarmsCopy
        for (let i = delta - 1; i >= 0; i--) {
          useAlarmsStore().allToatsAlarm.push(useAlarmsStore().allActiveAlarmsGetters[i])
        }
        this.emitter.emit('playAudio')
      }
    },
    findPatient (alarm) {
      return this.patients.find(p => {
        return alarm.customuser === p.id
      })
    },
    returnType (type) {
      let name
      if ('hr' === type) {
        name = 'Battito cardiaco'
      } else if ('pai' === type) {
        name = 'Intensità movimento'
      } else if ('md' === type) {
        name = 'Uomo a terra'
      }
      return name
    },
    changeVariables () {
      document.documentElement.style.setProperty('--x-small', '1.4vmax');
      document.documentElement.style.setProperty('--small', '1.6vmax');
      document.documentElement.style.setProperty('--medium', '1.9vmax');
      document.documentElement.style.setProperty('--large', '2.1vmax');


      document.documentElement.style.setProperty('--line-height-small', '1.6vmax');
      document.documentElement.style.setProperty('--line-height-medium', '2vmax');
      document.documentElement.style.setProperty('--line-height-large', '2.2vmax');
      document.documentElement.style.setProperty('--line-height-x-large', '2.6vmax');
    },
    resetVariables () {
      document.documentElement.style.setProperty('--x-small', '1.4vmin');
      document.documentElement.style.setProperty('--small', '1.6vmin');
      document.documentElement.style.setProperty('--medium', '1.9vmin');
      document.documentElement.style.setProperty('--large', '2.1vmin');


      document.documentElement.style.setProperty('--line-height-small', '1.6vmin');
      document.documentElement.style.setProperty('--line-height-medium', '2vmin');
      document.documentElement.style.setProperty('--line-height-large', '2.2vmin');
      document.documentElement.style.setProperty('--line-height-x-large', '2.6vmin');
    },
  }
}
</script>

<style>
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
}

.container-notifications {
  display: flex;
  flex-direction: column;
  position: fixed;
  right: 0;
  overflow: auto;
  padding: 2.5%;
  width: 40%;
  bottom: 0;
}

.btn-close-all.btn {
  background: #000000;
  text-transform: uppercase;
  border-radius: 5px;
  color: var(--color-white);
  margin: 10px auto 0px auto !important;
}

.text-type {
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 14px;

  color: var(--color-white);
}

.text-italic {
  font-style: italic;
  font-weight: var(--font-weight-regular);
  font-size: 14px;
  line-height: 14px;

  color: var(--color-white);
}

.container-single-notification span {
  margin-right: 10px;
}

.container-single-notification {
  margin: 5px 0px;
  display: flex;
  align-items: center;
  background: var(--color-danger-custom);
  padding: 20px;
  box-shadow: 4px 4px 10px rgba(195, 206, 217, 0.2);
  border-radius: 10px;
  color: var(--color-white);
}

.margin-top {
  margin-top: auto !important;
}

.img-rotate {
  /*margin-left: auto;*/
  transform: rotate(45deg);
}
</style>
