import {createRouter, createWebHistory} from 'vue-router'
import {useAuthStore} from "@/stores/auth";

const routes = [
  {
    path: '/',
    name: 'home',
    component: () => import(/* webpackChunkName: "Home" */ '../views/HomeView.vue'),
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/login',
    name: 'login',
    meta: {
      hideForAuth: true
    },
    component: () => import(/* webpackChunkName: "Login" */ '../views/LoginView.vue')
  },
  {
    path: '/patients',
    name: 'patients',
    component: () => import(/* webpackChunkName: "Patients" */ '../views/PazientiView.vue'),
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/reset-password',
    name: 'reset-password',
    component: () => import(/* webpackChunkName: "ResetPassword" */ '../views/ResetPassword'),
    meta: {
      hideForAuth: true
    }
  },
  {
    path: '/patients/:id',
    name: 'detailPatient',
    component: () => import(/* webpackChunkName: "DettaglioPaziente" */ '../views/DettagliPaziente'),
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/dashboard',
    name: 'dashboard',
    component: () => import(/* webpackChunkName: "Dashboard" */ '../views/DashboardView.vue'),
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/profileDoctor/:id',
    name: 'profileDoctor',
    component: () => import(/* webpackChunkName: "ProfileDoctor" */ '../views/ProfileDoctor'),
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/profilePatient/:id',
    name: 'profilePatient',
    component: () => import(/* webpackChunkName: "ProfilePatient" */ '../views/ProfilePatient'),
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/agenda',
    name: 'agenda',
    component: () => import(/* webpackChunkName: "Agenda" */ '../views/AgendaView.vue'),
    meta: {
      requiresAuth: true
    }
  },
    {
        path: '/:pathMatch(.*)*',
        redirect: "/",
    }
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})


router.beforeEach((to, from, next) => {
  if (to.matched.some((record) => record.meta.requiresAuth)) {
    if (useAuthStore().isAuthenticated) {
      next();
    } else {
      next("/login");
    }
  } else {
    next();
  }
});

router.beforeEach((to, from, next) => {
  if (to.matched.some((record) => record.meta.hideForAuth)) {
    if (useAuthStore().isAuthenticated) {
      next("/");
      return;
    }
    next();
  } else {
    next();
  }
});


export default router
