import axios from 'axios'
import {defineStore} from 'pinia'
import {useAuthStore} from "@/stores/auth";
import {usePatientsStore} from "@/stores/patients";
import moment from "moment";


export const useAlarmsStore = defineStore({
  id: 'alarms',

  state: () => ({
    isPresentAlarm: false,
    globalAlarm: false,
    alarmsHistory: null,
    alarms: [],
    alarmsWithQueryParams: [],
    arrayAlarmsInfinity: [],
    allActiveAlarms: [],
    countAllActiveAlarms: 0,
    allToatsAlarm: [],
    mostra: '',
    hr_notification: [],
    pai_notification: [],
    checked_hr: false,
    checked_pai: false,
    alarm_hr: '',
    alarm_pai: '',
    interval: null,
    spinnerAlarm: false,
    allAlarms: null,
    alarmsSliced: []
  }),
  actions: {
    getAlarms (payload) {
      return new Promise((resolve, reject) => {
        const config = {
          headers: {
            Authorization: 'Token ' + useAuthStore().userGetters.user.token
          }
        }

        let url = new URL(axios.defaults.baseURL + 'api/doctors/list-patients-alarm/')

        if (payload.queryParams !== null) {
          let params = new URLSearchParams()
          for (let i = 0; i < Object.keys(payload.queryParams).length; i++) {
            params.set(Object.keys(payload.queryParams)[i], Object.values(payload.queryParams)[i])
          }
          url.search = params
          axios.get(url.pathname + url.search, config)
            .then(res => {
              this.alarmsWithQueryParams = res.data
              resolve(res)
            })
            .catch(err => {
              reject(err)
            })
        } else {
          axios.get(url.pathname, config)
            .then(res => {
              this.alarmsWithQueryParams = res.data
              resolve(res)
            })
            .catch(err => {

              reject(err)
            })
        }
      })
    },
    getAllAlarms (payload) {
      return new Promise((resolve, reject) => {
        const config = {
          headers: {
            Authorization: 'Token ' + useAuthStore().userGetters.user.token
          }
        }

        let url = new URL(axios.defaults.baseURL + 'api/doctors/list-patients-alarm/')
        this.spinnerAlarm = true
        if (payload.queryParams !== null) {
          let params = new URLSearchParams()
          for (let i = 0; i < Object.keys(payload.queryParams).length; i++) {
            params.set(Object.keys(payload.queryParams)[i], Object.values(payload.queryParams)[i])
          }
          params.set('all', true)
          url.search = params
          axios.get(url.pathname + url.search, config)
            .then(res => {
              this.spinnerAlarm = false
              this.allAlarms = res.data
              if (payload.queryParams.scroll) {
                this.arrayAlarmsInfinity.push(...res.data.results)
              } else {
                this.arrayAlarmsInfinity = res.data.results
              }
              resolve(res)
            })
            .catch(err => {
              this.spinnerAlarm = false
              reject(err)
            })
        } else {
          axios.get(url.pathname, config)
            .then(res => {
              this.allAlarms = res.data
              this.spinnerAlarm = false
              let a = []
              a.push(...res.data.results);
              this.arrayAlarmsInfinity = a
              resolve(res)
            })
            .catch(err => {

              reject(err)
            })
        }
      })
    },
    getAllActiveAlarms (payload) {
      return new Promise((resolve, reject) => {
        const config = {
          headers: {
            Authorization: 'Token ' + useAuthStore().userGetters.user.token
          }
        }

        let url = new URL(axios.defaults.baseURL + 'api/doctors/list-patients-alarm/')
        this.spinnerAlarm = true
        if (payload.queryParams !== null) {
          let params = new URLSearchParams()
          for (let i = 0; i < Object.keys(payload.queryParams).length; i++) {
            params.set(Object.keys(payload.queryParams)[i], Object.values(payload.queryParams)[i])
          }
          url.search = params
          axios.get(url.pathname + url.search, config)
            .then(res => {
              this.spinnerAlarm = false
              this.allActiveAlarms = res.data
              resolve(res)
            })
            .catch(err => {
              this.spinnerAlarm = false
              reject(err)
            })
        } else {
          axios.get(url.pathname, config)
            .then(res => {
              this.allActiveAlarms = res.data
              resolve(res)
            })
            .catch(err => {

              reject(err)
            })
        }
      })
    },
    setGlobalAlarm (payload) {
      return new Promise((resolve) => {
        this.global_alarm = payload
        resolve()
      })
    },
    setInterval (payload) {
      return new Promise((resolve) => {
        this.interval = payload
        resolve()
      })
    },
    setAlarm (payload) {
      return new Promise(() => {
        this.alarms = payload
      })
    },
    retrieveAlarmsWithoutInterval (payload) {
      return new Promise((resolve, reject) => {
        const now = moment().hour(0).minute(0).second(0).millisecond(0).toISOString()
        usePatientsStore().getPatients({ queryParams: {alarm_history__init_timestamp__gte: now}})
          .then(res => {
            console.log('res', res.data)
            const patients = usePatientsStore().patientsGetters

            const config = {
              headers: {
                Authorization: 'Token ' + useAuthStore().userGetters.user.token
              }
            }

            const now = moment().hour(0).minute(0).second(0).millisecond(0).toISOString()
            let url = new URL(axios.defaults.baseURL + 'api/doctors/list-patients-alarm/')
            let params = new URLSearchParams()
            if (payload.queryParams !== null) {
              for (let i = 0; i < Object.keys(payload.queryParams).length; i++) {
                params.set(Object.keys(payload.queryParams)[i], Object.values(payload.queryParams)[i])
              }
            }
            params.set('pagination', false)
            params.set('end_timestamp__isnull', true)
            params.set('init_timestamp__gte', now)
            url.search = params

            let isPresentAlarmArray = []
            axios.get(url.pathname + url.search, config)
              .then(res => {
                console.log(res)
                this.alarmsSliced = res.data.slice(0, 5)
                console.log(this.alarmsSliced)
                patients.forEach(patient => {
                  res.data.forEach(alarm => {
                    if (patient.id === alarm.customuser) {
                      if (patient.hr_time_domain_limit[alarm.alarm_type + '_notification']) {
                        isPresentAlarmArray.push(true)
                      } else {
                        isPresentAlarmArray.push(false)
                      }
                    }
                  })
                })
                console.log(isPresentAlarmArray)
                let isPresentAlarmFinalComparison = false
                isPresentAlarmArray.forEach(item => {
                  isPresentAlarmFinalComparison = isPresentAlarmFinalComparison || item
                })
                console.log(isPresentAlarmFinalComparison)
                this.isPresentAlarm = isPresentAlarmFinalComparison
              })
              .catch(() => {
              })
          })
          .catch(err => {
            reject(err)
          })
        resolve()
      })
    },
    retrieveAlarms () {
      return new Promise((resolve) => {
        clearInterval(useAlarmsStore().intervalGetters)
        const intervalID = setInterval(() => {
          useAlarmsStore().retrieveAlarmsWithoutInterval({queryParams: null})
            .then(() => {

            })
            .catch(() => {

            })
          useAlarmsStore().setInterval(intervalID)
            .then(() => {
            })
            .catch(() => {
            })
          resolve()
        }, 15000)
      })
    },
    getAlarmsHistory (payload) {
      return new Promise((resolve, reject) => {

        const config = {
          headers: {
            Authorization: 'Token ' + useAuthStore().userGetters.user.token
          }
        }

        let url = new URL(axios.defaults.baseURL + 'api/alarm-history/')

        if (payload.queryParams !== null) {
          let params = new URLSearchParams()
          for (let i = 0; i < Object.keys(payload.queryParams).length; i++) {
            params.set(Object.keys(payload.queryParams)[i], Object.values(payload.queryParams)[i])
          }
          url.search = params
          axios.get(url.pathname + url.search, config)
            .then(res => {
              this.alarmsHistory = res.data
              resolve(res)
            })
            .catch(err => {
              reject(err)
            })
        } else {
          axios.get(url.pathname, config)
            .then(res => {
              this.alarmsHistory = res.data
              resolve(res)
            })
            .catch(err => {
              reject(err)
            })
        }
      })
    },
    editAlarmHistory (payload) {
      return new Promise((resolve, reject) => {

        const config = {
          headers: {
            Authorization: 'Token ' + useAuthStore().userGetters.user.token
          }
        }

        axios.patch('api/alarm-history/' + payload.id + '/', payload, config)
          .then(res => {
            resolve(res)
          })
          .catch(err => {
            reject(err)
          })
      })
    }
  },

  getters: {
    checked_hrGetters: state => {
      return state.checked_hr
    },
    alarmsGetters: state => {
      return state.alarms
    },
    mostraGetters: state => {
      return state.mostra
    },
    hr_notificationGetters: state => {
      return state.hr_notification
    },
    pai_notificationGetters: state => {
      return state.pai_notification
    },
    intervalGetters: state => {
      return state.interval
    },
    allAlarmsGetters: state => {
      return state.allAlarms
    },
    alarmsWithQueryParamsGetters: state => {
      return state.alarmsWithQueryParams
    },
    spinnerAlarmGetters: state => {
      return state.spinnerAlarm
    },
    arrayAlarmsInfinityGetters: state => {
      return state.arrayAlarmsInfinity
    },
    isPresentAlarmGetters: state => {
      return state.isPresentAlarm
    },
    alarmsHistoryGetters: state => {
      return state.alarmsHistory
    },
    alarmsSlicedGetters: state => {
      return state.alarmsSliced
    },
    allActiveAlarmsGetters: state => {
      return state.allActiveAlarms
    },
    allToatsAlarmGetters: state => {
      return state.allToatsAlarm
    },
    countAllActiveAlarmsGetters: state => {
      return state.countAllActiveAlarms
    }
  }
})


