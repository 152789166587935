import router from './router'
import BootstrapVue3 from 'bootstrap-vue-3'

import './assets/css/main.css'
import 'bootstrap/dist/css/bootstrap.css'
import 'bootstrap-vue-3/dist/bootstrap-vue-3.css'
import moment from 'moment'


import { createApp } from 'vue'
// import the root component App from a single-file component.
import App from './App.vue'
import axios from 'axios'
import {defineRule} from "vee-validate";
import mitt from 'mitt';
import {createPinia} from 'pinia'
import {createPersistedStatePlugin} from 'pinia-plugin-persistedstate-2'
import Datepicker from '@vuepic/vue-datepicker';
import '@vuepic/vue-datepicker/dist/main.css'
import {useAuthStore} from "@/stores/auth";
import {useApiSettingStore} from "@/stores/apiSettings";
import 'chartjs-adapter-moment';

const pinia = createPinia()

defineRule('required', value => {
  if (!value || !value.length) {
    return 'Questo campo è obbligatorio';
  }
  return true;
});

// create an .env.local file to store the local backend address. This will prevent the setting from being tracked on git
// for more environment variables, if you are using Vue cli 3, only variables that start with VUE_APP_ will be loaded.
// An env file for a specific mode (e.g. .env.production) will take higher priority than a generic one (e.g. .env).
axios.defaults.baseURL = process.env.VUE_APP_BACKEND_HOST
moment.locale('it')



axios.interceptors.response.use(undefined, function (error) {
    if (error.response) {
        if (error.response.status === 401) {
            useAuthStore().$reset()
            let string_split
            Object.keys(error.response.data).forEach(el => {
                string_split = el.split('-')
                if (string_split[1] === 'expired_token') {
                    useAuthStore().errorToken = 'Sessione scaduta'
                } else if (string_split[1] === 'invalid_token') {
                    useAuthStore().errorToken = 'Rieffettuare il login'
                } else if (el === 'code-invalid_code') {
                    useAuthStore().errorToken = error.response.data['code-invalid_code']
                } else if (el === 'authorization-user_inactive') {
                    useAuthStore().errorToken = error.response.data['authorization-user_inactive']
                }else if (el === 'detail') {
                    useAuthStore().errorToken = error.response.data['detail']
                }
            })
            router.push('/login').catch(() => {
            })
            return Promise.reject(error);
        }
    }
    if (error.message === 'Network Error') {
        useApiSettingStore().errorNetwork = true
    }
    return Promise.reject(error);
})

const emitter = mitt();
const app = createApp(App)
app.use(router)
app.use(pinia)
pinia.use(createPersistedStatePlugin())
app.use(BootstrapVue3)
app.config.globalProperties.emitter = emitter
app.component('DatePicker', Datepicker);
app.mount('#app')
